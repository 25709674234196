.SubnavigationButton {
  display: inline-block;
  background-color: var(--modal_card_background);
  color: var(--text_primary);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08);
  user-select: none;
}

.SubnavigationButton__in {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubnavigationButton__before {
  margin-right: 8px;
  color: var(--accent);
}

.SubnavigationButton__before .Icon--24 {
  margin-left: -4px;
}

.SubnavigationButton__label {
  flex-grow: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SubnavigationButton__after {
  margin-left: 8px;
}

.SubnavigationButton__expandableIcon {
  margin-left: 8px;
  margin-top: 1px; /* Смещает иконку относительно текста */
  color: var(--icon_secondary);
}

/**
 * selected
 */

.SubnavigationButton--selected {
  background-color: var(--button_primary_background);
  color: var(--button_primary_foreground);
}

.SubnavigationButton--selected .SubnavigationButton__before {
  color: inherit;
}

.SubnavigationButton--selected .Counter {
  background-color: var(--button_primary_foreground);
  color: var(--button_primary_background);
}

.SubnavigationButton--selected .SubnavigationButton__expandableIcon {
  color: inherit;
}

/**
 * size m
 */

.SubnavigationButton--m .SubnavigationButton__in {
  height: 36px;
  padding: 0 14px;
}

/**
 * size l
 */

.SubnavigationButton--l .SubnavigationButton__in {
  height: 44px;
  padding: 0 14px;
}

/**
 * iOS
 */

.SubnavigationButton--ios {
  border-radius: 10px;
}

/**
 * Android
 */

.SubnavigationButton--android {
  border-radius: 8px;
}

/**
 * VKCOM
 */

.SubnavigationButton--vkcom {
  border-radius: 4px;
}

/**
 * CMP:
 * SubnavigationBar
 */
.SubnavigationBar .SubnavigationButton + .SubnavigationButton {
  margin-left: var(--vkui--size_subnavigation_bar_gap--regular);
}

.SubnavigationBar--fixed .SubnavigationButton {
  flex: 1;
  min-width: 0;
}
