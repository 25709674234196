.CardGrid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.CardGrid--spaced {
  padding: var(--vkui--size_base_padding_vertical--regular)
    var(--vkui--size_base_padding_horizontal--regular);
}

.CardGrid .Card {
  margin-right: var(--vkui--size_cardgrid_padding--regular);
}

.CardGrid--l .Card {
  width: 100%;
  margin-right: 0;
}

.CardGrid--l .Card:not(:first-child) {
  margin-top: var(--vkui--size_cardgrid_padding--regular);
}

.CardGrid--m .Card {
  width: calc((100% - 8px) / 2);
}

.CardGrid--m .Card:nth-child(n + 3) {
  margin-top: var(--vkui--size_cardgrid_padding--regular);
}

.CardGrid--m .Card:nth-child(2n) {
  margin-right: 0;
}

.CardGrid--s .Card {
  width: calc((100% - 2 * 8px) / 3);
}

.CardGrid--s .Card:nth-child(n + 4) {
  margin-top: var(--vkui--size_cardgrid_padding--regular);
}

.CardGrid--s .Card:nth-child(3n) {
  margin-right: 0;
}

.CardGrid--sizeX-compact {
  padding-left: var(--vkui--size_base_padding_horizontal--regular);
  padding-right: var(--vkui--size_base_padding_horizontal--regular);
}

.CardGrid + .CardGrid {
  margin-top: 16px;
}

/**
 * Group
 */

.Group--card .CardGrid {
  padding-left: 8px;
  padding-right: 8px;
}

.Group .CardGrid:first-child {
  margin-top: 8px;
}

.Group .CardGrid:last-child {
  margin-bottom: 8px;
}
