.WriteBar {
  background: var(--background_content, var(--vkui--color_background_modal));
}

.WriteBar--shadow {
  box-shadow: var(--vkui--elevation3);
}

.WriteBar__before,
.WriteBar__form,
.WriteBar__after {
  display: flex;
  align-items: flex-end;
}

.WriteBar__form {
  min-height: 52px;
}

.WriteBar__formIn {
  flex: 1;
  min-width: 0;
  display: flex;
  position: relative;
}

.WriteBar__textarea {
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  min-width: 0;
  margin: 0;
  background: transparent;
  border: none;
  resize: none;
  color: var(--text_primary, var(--vkui--color_text_primary));
  -webkit-appearance: none;
  max-height: 120px;
}

.WriteBar__textarea::placeholder {
  color: var(--text_placeholder, var(--vkui--color_text_subhead));
  /* Для Firefox */
  opacity: 1;
}

.WriteBar__inlineAfter {
  display: flex;
  align-items: flex-end;
}

.WriteBar__before {
  padding-left: 4px;
}

.WriteBar__after {
  padding-right: 4px;
}

.WriteBar__textarea {
  height: 52px;
  padding: 16px 12px;
}

/**
 * iOS
 */

.WriteBar--ios .WriteBar__before,
.WriteBar--ios .WriteBar__after {
  padding: 0 4px;
}

.WriteBar--ios .WriteBar__formIn {
  box-sizing: border-box;
  background-color: var(
    --input_background,
    var(--vkui--color_field_background)
  );
  border: var(--thin-border) solid
    var(--input_border, var(--vkui--color_field_border_alpha));
  border-radius: 18px;
  margin: 8px 0;
}

.WriteBar--ios .WriteBar__formIn:first-child {
  margin-left: 12px;
}

.WriteBar--ios .WriteBar__inlineAfter {
  margin-right: -1px;
  margin-top: -9px;
  margin-bottom: -9px;
}

.WriteBar--ios .WriteBar__textarea {
  height: 34px;
  padding: 6px 11px;
  font-size: 17px;
  line-height: 22px;
}
