.FormField {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: var(--vkui--size_field_height--regular);
}

/* stylelint-disable-next-line vkui/atomic */
.FormField > * {
  border-radius: inherit;
  z-index: 6;
}

/**
 * TODO v5.0.0 поправить под новую адаптивность
 */
.FormField--sizeY-compact {
  min-height: var(--vkui--size_field_height--compact);
}

.FormField__before,
.FormField__after {
  position: relative;
  z-index: 7;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: var(--vkui--size_field_height--regular);
  height: 100%;
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
}

/**
 * TODO v5.0.0 поправить под новую адаптивность
 */
.FormField--sizeY-compact .FormField__before,
.FormField--sizeY-compact .FormField__after {
  min-width: var(--vkui--size_field_height--compact);
  height: var(--vkui--size_field_height--compact);
}

.FormField__before {
  color: var(--accent, var(--vkui--color_icon_accent));
}

.FormField__after {
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
}

.FormField__border {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform-origin: left top;
  border: var(--thin-border) solid transparent;
  border-radius: var(--vkui--size_border_radius--regular);
}

.FormField--default .FormField__border {
  border-color: var(--field_border, var(--vkui--color_field_border_alpha));
  background-color: var(
    --field_background,
    var(--vkui--color_field_background)
  );
}

/**
 * [start]
 * CMP:
 * FormItem
 */
.FormItem--error .FormField__border,
.FormField--status-error .FormField__border {
  background-color: var(
    --field_error_background,
    var(--vkui--color_background_negative_tint)
  );
  border-color: var(--field_error_border, var(--vkui--color_stroke_negative));
  z-index: 3;
}

.FormItem--valid .FormField__border,
.FormField--status-valid .FormField__border {
  border-color: var(--field_valid_border, var(--vkui--color_stroke_positive));
  z-index: 3;
}
/* [end] */

.FormField--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
  cursor: default;
  pointer-events: none;
}

.FormField--hover .FormField__border {
  background-color: var(
    --field_background,
    var(--vkui--color_field_background)
  );
}

.FormField--default.FormField--hover .FormField__border {
  border-color: var(
    --icon_tertiary_alpha,
    var(--vkui--color_field_border_alpha--hover)
  );
  z-index: 4;
}

/**
 * FIXME: необходимо заменить на `useFocusVisible()`
 */
.FormField:focus-within .FormField__border,
.FormField *:focus ~ .FormField__border {
  border-color: var(--accent, var(--vkui--color_stroke_accent));
  z-index: 5;
}

/**
 * CMP:
 * ModalCardBase
 */
.ModalCardBase__header + .FormField,
.ModalCardBase__subheader + .FormField {
  margin-top: 16px;
}

/**
 * CMP:
 * Select
 */
.Select--pop-down .FormField__border {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Select--pop-up .FormField__border {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/**
 * CMP:
 * CalendarHeader
 */
.CalendarHeader__picker .FormField__after {
  min-width: 12px;
  padding-right: 8px;
}

/**
 * CMP:
 * ChipsInput
 */
.ChipsInput .FormField__after {
  z-index: 7;
}

/**
 * CMP:
 * FormLayoutGroup
 */
.FormLayoutGroup--segmented .FormField {
  z-index: auto;
}

.FormLayoutGroup--segmented
  > .FormItem:not(:first-of-type):not(:last-of-type)
  .FormField__border,
.FormLayoutGroup--segmented
  .Removable__content
  > .FormItem:not(:first-of-type):not(:last-of-type)
  .FormField__border {
  border-radius: 0;
}

.FormLayoutGroup--segmented
  > *:first-of-type:not(:last-of-type)
  .FormField__border,
.FormLayoutGroup--segmented
  .Removable__content
  > *:first-of-type:not(:last-of-type)
  .FormField__border {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.FormLayoutGroup--segmented
  > .FormItem:not(:first-of-type):last-of-type
  .FormField__border,
.FormLayoutGroup--segmented
  .Removable__content
  > .FormItem:not(:first-of-type):last-of-type
  .FormField__border {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
