.PanelHeaderContext--opened,
.PanelHeaderContext--closing {
  width: 100%;
  height: 100%;
}

.PanelHeaderContext--desktop.PanelHeaderContext--opened,
.PanelHeaderContext--desktop.PanelHeaderContext--closing {
  height: auto;
}

.PanelHeaderContext__in {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
}

.PanelHeaderContext__fade {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}

.PanelHeaderContext--opened .PanelHeaderContext__fade {
  animation: vkui-animation-panelheadercontext-fade-in 0.2s ease;
}

.PanelHeaderContext--closing .PanelHeaderContext__fade {
  animation: vkui-animation-panelheadercontext-fade-out 0.2s ease;
}

.PanelHeaderContext--opened .PanelHeaderContext__in {
  animation: vkui-animation-panelheadercontext-translate-in 0.2s ease;
}

.PanelHeaderContext--closing .PanelHeaderContext__in {
  animation: vkui-animation-panelheadercontext-translate-out 0.2s ease;
}

.PanelHeaderContext .List {
  background: var(--header_background, var(--vkui--color_background_content));
}

/*
 Android
 */
.PanelHeaderContext__in {
  padding: 8px;
  box-sizing: border-box;
}

.PanelHeaderContext--ios .PanelHeaderContext__in {
  padding: 0;
}

.PanelHeaderContext--rounded .List {
  overflow: hidden;
  border-radius: 12px;
  padding: 8px 0;
}

/*
 Desktop
 */

.PanelHeaderContext--desktop .PanelHeaderContext__in {
  padding: 12px 8px;
}

.PanelHeaderContext--desktop .PanelHeaderContext__content {
  border-radius: 12px;
  overflow: hidden;
  background: var(--header_background, var(--vkui--color_background_content));
}

.PanelHeaderContext--desktop .PanelHeaderContext__content {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 8px 8px rgba(0, 0, 0, 0.16);
}

/*
  Animations
 */

@keyframes vkui-animation-panelheadercontext-translate-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes vkui-animation-panelheadercontext-translate-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes vkui-animation-panelheadercontext-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes vkui-animation-panelheadercontext-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
